<template>
<div class="page-wrapper">
  <Loading v-if="loading"></Loading>
  <div class="page-banner bg" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="container">
      <div class="page-name common-padding">
         <h1>{{ $t("about") }}</h1>
       </div>
    </div>
  </div>

  <div class="container common-padding">
    <div class="about">
      <div class="about-img">
        <img :src="img">
      </div>
      <div class="about-txt">
        <h3>{{ $t("about") }}</h3>
        <div v-html="editorDescription"></div>
      </div>
    </div>

    <div class="common-btn">
      <router-link to="/contact">
        <div class="common-btn-txt">
          {{ $t("quote") }}
        </div>
      </router-link>
    </div>
  </div>
  <Copyright></Copyright>
</div>
</template>

<script>

import Copyright from "@/components/Copyright";
import Loading from "@/components/loading";
import {getAboutInfo} from "@/network/main";

export default {
  name: "About",
  components:{
    Copyright,
    Loading,
  },
  data(){
    return{
      loading:true,
      banner:'',
      img:'',
      content:'',
    }
  },
  created() {
    this.getAboutInfo();
  },
  methods: {
    getAboutInfo() {
      getAboutInfo().then(res => {
        this.loading = false;
        const data = res.data.data
        this.banner = data.banner.about_us_banner;
        this.img = data.about_us.images
        this.content = data.about_us.description;
      })
    }
  },
  computed: {
    editorDescription() {
      if (this.content) {
        return this.content.replace(/\r?\n/g, "<br>");
      }
      return "";
    },
  },
}
</script>

<style scoped>
.about{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-img{
  width: 50%;
}
.about-txt{
   width: 45%;
}

.about-txt h3{
  padding-bottom: 20px;
}

.about-txt p{
  padding: 5px 0;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .about{
    flex-wrap: wrap;
  }
  .about-img{
    width: 100%;
    order: 2;
    margin-top: 20px;
  }
  .about-txt{
    width: 100%;
    order: 1;
  }
}

</style>